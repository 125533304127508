p {
	margin: 0px !important;
	/* color: white; */
}

.App {
	max-width: 90%;
	margin: 0 auto;
}

#root, body, .App {
	background: whitesmoke;
}

html, body, #root {
	min-height: 100vh;
}

.sq-app-container {
	max-width: 1200px;
	border-radius: 5px;
	background: white;
	margin: 0 auto;
	text-align: center;
	box-shadow: 0 3px 30px rgb(0 0 0 / 0.5);
}

#sq-app {
	max-width: 80%;
	margin: 0 auto;
	padding-bottom: 90px;
	border-bottom: 1px solid rgb(220, 220, 220);
}

.sq-select {
	padding: 15px;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
}

/* #root {
  max-width: 80%;
  margin: 0 auto;
  border-radius: 30px;
  background: whitesmoke;
} */

/* ==== TOP NAV BAR ==== */
.sq-nav {
	background: rgb(30, 35, 45);
	padding: 5px;
	margin-bottom: 25px;
}

.sq-nav-container{
	max-width: 1500px;
	display: flex;
	margin: 0 auto;
}

.sq-nav-button, #dropdown-basic-button {
	background: none;
	border: none;
	font-size: x-large;
	box-sizing: border-box;
	padding: 0px 15px;
	transition: .5s;
	color: whitesmoke;
}

.sq-nav-button:hover {
	color: grey;
	cursor: pointer;
}

.sq-nav-active {
	border-bottom: 1px solid rgb(50, 230, 222);
}

#nav-logo {
	padding: 0px;
	margin: 10px 30px;
	max-height: 50px;
}

#dropdown-basic-button {
	margin-top: 16px;
}

.sq-nav-import {
	justify-content: end;
}

.sq-nav-option {
	color: rgb(30, 35, 45)
}

#nav-text {
	display: inline-flex;
	color: whitesmoke;
	align-items: center;
	font-size: x-large;
	font-weight: 300;
}

#nav-text:hover {
	cursor:default;
}

#nav-spacer {
	width: 1px;
	display: inline-flex;
	background-color: rgb(220, 220, 220);
	margin: 5px 30px;
	border-radius: 10px;
}

/* END NAV BAR */

form {
	margin: 0 auto;
}

h1,h6 {
	font-weight: 100;
	/* color: white; */
	text-align: center;
	padding-bottom: 10px;
	border-bottom: 1px solid rgb(220, 220, 220);
}

h6 {
	border-bottom: none
}

.sq-image-button {
	box-sizing: border-box;
	border-radius: 4px;
	padding: 10px 15px;
	margin-bottom: 10px;
	font-size: 18px;
}

.sq-label {
	line-height: 2;
	text-align: left;
	display: block;
	margin-bottom: 13px;
	margin-top: 20px;
	/* color: white; */
	font-size: 20px;
	font-size: x-large
}

.sq-header {
	padding-top: 30px;
	text-align: center;
}

.sq-disabled {
  visibility: hidden;
}

hr {
	margin-top: 30px;
}

.sq-logo {
	padding-top: 25px;
	padding-bottom: 25px;
	max-width: 250px;
	max-height: 250px;
}

.sq-image-button:before {
	content: attr(data-hover);
}

.sq-image-button:hover:before {
	opacity: 1;
	visibility: visible;
}

.sq-image-button {
	width: 1in;
	height: 1in;
	/* background-color: #DDE5E3; */
  border-radius: 25px;
	transition: .75s;
	margin: 0px 22px;
	-webkit-box-reflect: below -1px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.075));
}

.sq-image-button:hover {
	/* background-color:#02C4AE; */
  background-color: rgb(50, 230, 222);
}

.sq-label {
	text-align: center;
}

#select-game-div {
	padding-top: 0;
}

#sq-stats-div {
	margin-top: 30px;
	/* color: white; */
	text-align: center;
}

#sq-data {
  max-width: 1600px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 30px;
  padding-left: 3px;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}

.sq-active-button {
  background-color: rgb(8, 139, 139)
}

.sq-active-button:hover {
  background-color: rgb(8, 139, 139);
}

.sq-loading {
	height: 800px;
	width: auto;
	margin: 0 auto;
	align-items: center;
	display: flex;
	justify-content: center;
}

.sq-loading-text {
	margin: 0 auto;
}

#sq-select-dataset {
	box-sizing: border-box;
	border-radius: 4px;
	padding: 10px 15px;
	font-size: 16px;
	margin: 30px;
	transition: .75s;
}

#sq-select-dataset:hover {
	cursor: pointer;
}

.sq-inactive{
	visibility: hidden;
}

.MuiDataGrid-columnHeaderTitle {
	font-weight: bold !important;
}

.sq-stats-div{
	height: 800px;
	width: 100%;
	border-radius: 5px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
}

.sq-table-cell {
	text-align: right;
}

/* LOGIN PAGE */
.sq-login-table {
	margin: auto;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
}
.sq-login-input {
	height: 20px;
	margin-left: 15px;
}
.sq-login-button-row {
	text-align: right
}
.sq-login-button {
	padding: 7px 15px;
	transition: .75s;
}
.sq-login-button:hover{
	cursor: pointer;
}
.sq-login-error {
	text-align: left;
	color: #bf1650;
	font-size: 18px;
}
.sq-login-error::before {
	display: inline;
	content: "⚠ ";
}
.sq-login-form {
	max-width: 500px;
}
.sq-login-label {
	line-height: 1.5;
	text-align: left;
	display: block;
	margin-bottom: 5px;
	margin-top: 20px;
	font-size: 22px;
}
.sq-login-input {
	display: block;
	box-sizing: border-box;
	padding: 10px 15px;
	font-size: 14px;
	margin: auto;
	margin-bottom: 10px;
	height: 35px;
	width: 100%;
}
input[type="submit"],
.sq-login-input, .delete, #sq-reset-filters-button {
	display: block;
	box-sizing: border-box;
	width: 100%;
	border-radius: 4px;
	border: 1px solid black;
	padding: 10px 15px;
	margin-bottom: 10px;
	font-size: 14px;
}
button[type="submit"],
input[type="submit"], .delete, #sq-reset-filters-button {
	background: rgb(8, 139, 139);
	color: white;
	text-transform: uppercase;
	border: none;
	margin-top: 40px;
	padding: 20px;
	font-size: 16px;
	font-weight: 100;
	letter-spacing: 5px;
	transition: .25s
}
input[type="submit"]:hover, #sq-reset-filters-button:hover{
	background: rgb(23, 114, 109);
}
.sq-login-inactive {
	display: none;
}

.sq-login-active {
	display: block;
}

#sq-login-errorbox {
	color: red;
	font-size: 18px;
	padding: 10px;
}
/* END LOGIN PAGE */

/* INPUT FORM */
.sq-import-form {
	margin-top: 30px;
	max-width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
}
.sq-import-input, .sq-import-refresh, .sq-import-button {
	height: 50px;
}
.sq-import-input {
	padding: 10px;
	text-align: left;
	font-size: large;
	width: 275px;
	border: none;
	border-right: 1px solid rgb(220, 220, 220);
	background: transparent;
}
#sq-import-message {
	margin-top: 15px;
	font-size: medium !important;
	width: 100%;
	word-wrap: break-word;
}
.sq-button-inactive {
	background-color: grey !important;
}
.sq-button-active{
	cursor: pointer;
}
#sq-import-button:hover {
	background-color: rgb(7, 90, 90);
}
.sq-import-header {
	margin: 0 auto;
}
.sq-import-text {
	font-size: large;
	border-bottom: 1px solid rgb(220, 220, 220);
	padding-bottom: 20px;
}
.sq-import-refresh {
	font-size: x-large;
	text-align: center;
	padding: 10px;
	padding-bottom: 5px;
	padding-top: 0px;
	vertical-align: middle;
	border-radius: 4px;
	border: 1px solid rgba(118, 118, 118, 0.3);
	transition: .25s;
	color: #5d8ee2;
}
.sq-import-refresh:hover {
	background-color: rgb(220, 220, 220);
}
.sq-import-refresh:active, .sq-import-button:active {
	transform: scale(95%)
}
.sq-import-button {
	margin: 0 !important;
	width: 200px !important;
	padding: 0 !important;
}
.valorant, .rocketleague {
	border: 1px solid rgb(220, 220, 220) !important;
	border-radius: 4px;
}
.no-margin-top{
	margin-top: 0;
}
.rl-import{
	height: 56px;
	margin: 8px;
}
/* END INPUT FORM */


@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1 }
}

#sq-app{
	animation: fadeIn 1s;
}

#sq-select-dataset {
	animation: fadeIn 1s;
}

/* UPLOAD PAGE */
#sq-upload {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 30px;
}

.upload {
	margin: 0px 30px!important;
	font-size: large;
}

#sq-select-game {
	padding: 10px 15px!important;
}

#sq-select-game:hover{
	cursor: pointer;
}

#sq-upload-file {
	width: 50%;
}
#sq-input-message{
	white-space: pre-wrap;
}
.sq-upload-delete{
	padding-left: 30px;
}
.delete {
	background-color: #bf1650;
	width: 85px!important;
	height: 35px!important;
	letter-spacing: 3px;
	font-size: small!important;
}
.delete:hover{
	background-color: #830e37;
}
.sq-upload-message{
	margin-top: 0px!important;
	padding-bottom: 15px;
	border-bottom: 1px solid rgb(220, 220, 220);
}
#sq-uploaded-files{
	font-size: large;
}
/* END UPLOAD PAGE */

/* DOWNLOAD PAGE */
#sq-download-header {
	padding: 20px!important;
	margin-bottom: 30px!important;
	border-bottom: 1px solid rgb(220, 220, 220);
}

#sq-download-table {
	font-size: medium;
}

#sq-download-div {
	padding: 20px;
}

/* END DOWNDLOAD PAGE */

/* EXPORT PAGE */
#sq-export, #sq-admin-adduser {
	width: 100%;
	justify-content: center;
	padding: 30px
}
.sq-export-filters {
	display: flex;
	justify-content: left;
	padding: 15px;
	flex-wrap: wrap;
}
.export-submit {
	display: flex;
	justify-content: left;
	margin-left: 10px;
}
.export{
	justify-content: right;
	width: 225px!important;
	margin-right: 15px!important;
}
.exportHeader{
	font-size: x-large;
	padding: 3px;
}
.sq-helpText {
	color: red;
}
/* END EXPORT PAGE */

/* ADMIN - ADD USER PAGE */
.addUserTable > tbody > tr > td {
	text-align: left;
	padding: 10px;
	vertical-align: top;
}
.addUserTable {
	display: flex;
	padding: 15px;
	padding-bottom: 0px;
}
/* END ADMIN - ADD USER PAGE */